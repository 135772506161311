:root {
    --bs-font-sans-serif:'.'!important;
}
body, *, *[class^="css-"] {
    font-family: '.'!important;
}
*:focus, :focus:not(.focus--mouse), .form-check [type=checkbox]:focus+label, .form-check [type=radio]:focus+label, .toggles label input[type=checkbox]:focus+.lever {
    outline: none!important;
    box-shadow: none!important;
}
.w-full {
    width: 100%!important;
}
.h-abc {
    height: calc(100svh - 60px)!important;
}
.bottom-nav  {
    z-index: 9999!important;
}
.material-icons {
    font-family: 'Material Icons'!important;
}
input[type=date], input[type=datetime-local], input[type=email], input[type=number], input[type=password], input[type=search], input[type=tel], input[type=text], input[type=time], input[type=url], textarea {
    border: none!important;
    border-bottom: none!important;
    border-radius: 0!important;
    padding: 16.5px 14px!important;
    outline: 0!important;
}
._2dbjY {
    margin:0!important;
    margin-bottom:10px!important;
    width:100%!important;
}
._2ry0A {
    max-width: 100%!important;
}